<template>
  <layout>
    <div class="max-w-5xl mx-auto xl:mt-4">
      <alert-warning v-if="! state.hasAccess" title="Access denied">
        You are not allowed to create cameras
      </alert-warning>

      <form-layout
        v-else
        @submit="create"
        title="Create Camera"
        icon="camera_indoor"
        :is-loading="state.isInitialLoading"
      >
        <div class="space-y-4">
          <errors :errors="state.errors" />
          <text-input label="Camera Id" v-model="state.form.camera_id" :required="true" />
          <text-input label="Camera Url/IP" v-model="state.form.url" :required="true" />
          <text-input label="Authentication Username" v-model="state.form.basic_auth_username" :required="true" />
          <text-input label="Authentication Password" v-model="state.form.basic_auth_password" :required="true" />
          <select-box label="Authentication Type" v-model="state.form.auth_type" :required="true">
            <option :value="null">Select Authentication Type</option>
            <option v-for="option in authTypeOptions" :key="option.value" :value="option.value">
              {{ option.label }}
            </option>
          </select-box>
        </div>

        <template #actions>
          <submit-button :is-loading="state.isSaving">Create</submit-button>
        </template>
      </form-layout>

      <div class="flex items-center my-4 lg:my-8">
        <span class="material-icons text-big-stone">chevron_left</span>
        <router-link :to="editRoute" class="text-sm hover:underline">Back</router-link>
      </div>
    </div>
  </layout>
</template>

<script>
import { reactive } from 'vue'
import http from '@/services/http.js'
import notification from '@/services/notifications.js'
import Layout from '@/layouts/Default.vue'
import FormLayout from '@/components/form/Layout.vue'
import Errors from '@/components/form/Errors.vue'
import TextInput from '@/components/form/Text.vue'
import SelectBox from '@/components/form/Select.vue'
import AlertWarning from '@/components/alerts/Warning.vue'
import { useRoute, useRouter } from 'vue-router'
import SubmitButton from '@/components/form/SubmitButton.vue'

export default {
  name: 'settings-machines-create',

  components: {
    Layout,
    AlertWarning,
    FormLayout,
    TextInput,
    SelectBox,
    SubmitButton,
    Errors,
  },

  setup() {
    const router = useRouter()
    const route = useRoute()
    const machineId = route.params.machineId
    const editRoute = { name: 'SettingsMachinesEdit', params: { id: machineId } }
    const state = reactive({
      hasAccess: true,
      errors: [],
      isInitialLoading: false,
      isSaving: false,
      form: {}
    })

    const authTypeOptions = [
      { value: 'digest', label: 'Digest' },
      { value: 'basic', label: 'Basic' },
    ]

    const checkCreatePermissions = async () => {
      state.isInitialLoading = true

      try {
        await http.get(`/machine/${route.params.machineId}/cameraedit`)
        state.hasAccess = true
      } catch (e) {
        if (e.response) {
          state.hasAccess = false
        } else {
          notification.error('Error', 'Something went wrong. Please, try again.')
        }
      }

      state.isInitialLoading = false
    }

    checkCreatePermissions()

    const redirectToMachineEdit = () => {
      router.push(editRoute)
    }

    const create = async () => {
      state.errors = []
      state.isSaving = true

      try {
        await http.post(`/machine/${machineId}/camera`, state.form)

        redirectToMachineEdit()
      } catch (e) {
        if (e.response) {
          state.errors.push(e.response.data.Message)
        } else {
          state.errors.push('Something went wrong. Please, try again.')
        }
      }

      state.isSaving = false
    }

    return {
      state,
      authTypeOptions,
      create,
      editRoute,
    }
  }
}
</script>
